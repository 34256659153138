
import { defineComponent } from "vue";
import ServicesComponent from "@/components/ServicesComponent.vue";

export default defineComponent({
  name: "services",
  components: {ServicesComponent},
  mounted() {},
  methods: {},
});
